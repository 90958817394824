/* eslint-disable */
export class XMLNode {
    attributes: { [key: string]: string } = {};
    children: XMLNode[] = [];
    lastChild: XMLNode = null;
    constructor(public tagName: string) {}

    appendChild(child: XMLNode) {
        this.children.push(child);
        this.lastChild = child;

        return this;
    }

    setAttribute(name: string, value: string) {
        this.attributes[name] = value;

        return this;
    }

    toString() {
        const self: XMLNode = this;

        return [
            '<',
            self.tagName,
            Object.keys(self.attributes)
                .map(function (attr) {
                    return [' ', attr, '="', self.attributes[attr], '"'].join('');
                })
                .join(''),
            '>',
            self.children
                .map(function (child: XMLNode) {
                    return child.toString();
                })
                .join(''),
            '</',
            self.tagName,
            '>'
        ].join('');
    }
}
