import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, combineLatest, distinctUntilChanged, takeUntil } from 'rxjs';
import * as fromRoot from '../../reducers';
import { UserAvailability } from '../availability.models';
import * as availabilityActions from '../../userAvailability/availability.actions';
import { UISetting, UISettingsService } from '../../services/ui-settings.service';
import { FeatureService } from '../../shared/services/feature.service';
import { Feature } from '../../shared/components/features/features.models';
import { ModifiedAccountInfo } from 'src/app/auth/auth.models';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'dir-user-calendar',
    templateUrl: './user-calendar.component.html',
    styleUrls: ['./user-calendar.component.scss'],
    animations: [
        trigger('showUp', [
            transition(':enter', [
                style({ trasform: 'scale(0.5)', opacity: 0 }),
                animate('0.3s', style({ transform: 'scale(1)', opacity: 1 }))
            ])
        ])
    ]
})
export class UserCalendarComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    email$ = new BehaviorSubject<string>('');
    private _email = '';
    @Input()
    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
        this.email$.next(this._email);
    }

    @Input() size = 'sm';
    @Output() dismiss = new EventEmitter();

    dayModifier = 0; //Day offset from today.  I.e. 0 = today, 1 = tomorrow, -1 = yesterday
    dayModifier$ = new BehaviorSubject<number>(0);
    timePeriods: string[][] = [];

    compareWithMe$ = new BehaviorSubject<boolean>(false);
    compareWithMe = false;
    showTimeLabels = true;
    show12HourTime = false;
    featureConsented = true;
    calendarAlertDismissed = false;
    userAvailability: UserAvailability = null;
    user$: Observable<ModifiedAccountInfo>;
    currentUserEmail = '';
    constructor(
        private store: Store<fromRoot.State>,
        private cdr: ChangeDetectorRef,
        private localStorage: UISettingsService,
        private featureService: FeatureService
    ) {
        this.compareWithMe = this.localStorage.getBoolValue(UISetting.Calendar_Compare_With_Me, false);
        this.showTimeLabels = this.localStorage.getBoolValue(UISetting.Calendar_Labels, true);
        this.show12HourTime = this.localStorage.getBoolValue(UISetting.Calendar_Show12Time, false);
        this.calendarAlertDismissed = this.localStorage.getBoolValue(UISetting.Calendar_Alert_Dismissed, false);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit() {
        this.store.pipe(takeUntil(this.unsubscribe$), select(fromRoot.selectUserAvailability)).subscribe((a) => {
            if (a && this.compareWithMe && this.currentUserEmail) {
                const schedules: string[] = [];
                const u1: string = a.find((ua) => ua.Mail == this.currentUserEmail).Availability;
                const u2: string = a.find((ua) => ua.Mail == this.email).Availability;
                schedules.push(u1 ? u1 : '');
                schedules.push(u2 ? u2 : '');
                this.timePeriods = this.splitIntoPeriods(this.getComparision(schedules));
                this.userAvailability = a.find((ua) => ua.Mail == this.email);
            } else {
                if (a) {
                    this.userAvailability = a.find((ua) => ua.Mail == this.email);
                    this.timePeriods = this.splitIntoPeriods(
                        this.userAvailability ? this.userAvailability.Availability : ''
                    );
                }
            }
        });

        const cal$ = this.featureService.hasFeature(Feature.UserAvailability);

        this.user$ = this.store.pipe(select(fromRoot.selectUser), distinctUntilChanged());

        combineLatest([this.dayModifier$, this.compareWithMe$, this.email$, cal$, this.user$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([dayModifier, compareWithMe, email, isCalendarAvailable, currentUser]) => {
                this.currentUserEmail = currentUser.username;
                if (isCalendarAvailable) {
                    // Calendar feature is available, load the schedule
                    this.store.dispatch(
                        availabilityActions.loadUserSchedule({
                            mail: [this.email, currentUser.username],
                            dayModifier: dayModifier,
                            compareWithMe: compareWithMe
                        })
                    );
                } else {
                    this.featureConsented = false;
                }
            });
    }

    onChangeDay(d: number): boolean {
        this.dayModifier += d;
        this.dayModifier$.next(this.dayModifier);
        return false;
    }

    onBookMeeting($event: MouseEvent | Event, availability: string, period: number, segment: number): boolean {
        console.log('book meeting');
        $event.preventDefault();
        $event.stopPropagation();

        const startTime = new Date(Date.now() + this.dayModifier * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
        const t = this.calculateTime(period, segment);
        const meetingTime = `${startTime}T${
            t.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }) + ':00'
        }`;

        this.store.dispatch(
            availabilityActions.bookMeeting({ mails: [this.email], startTime: meetingTime, duration: 30 })
        );

        return false;
    }

    onToggleCompareWithMe() {
        this.compareWithMe = !this.compareWithMe;
        this.compareWithMe$.next(this.compareWithMe);
        this.localStorage.setBoolValue(UISetting.Calendar_Compare_With_Me, this.compareWithMe);
    }

    onToggleShowTimeLabels() {
        this.showTimeLabels = !this.showTimeLabels;
        this.localStorage.setBoolValue(UISetting.Calendar_Labels, this.showTimeLabels);
    }

    onToggle12HourTime() {
        this.show12HourTime = !this.show12HourTime;
        this.localStorage.setBoolValue(UISetting.Calendar_Show12Time, this.show12HourTime);
    }

    onDismissCalendarAlert() {
        this.localStorage.setBoolValue(UISetting.Calendar_Alert_Dismissed, true);
        this.calendarAlertDismissed = true;
    }

    getAvailabilityDescription(): string {
        let result = '';
        switch (this.dayModifier) {
            case -1:
                result = 'Yesterday';
                break;
            case 0:
                result = 'Today';
                break;
            case 1:
                result = 'Tomorrow';
                break;
            default:
                result = new Date(Date.now() + this.dayModifier * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
                break;
        }
        return `${result}`;
    }

    //Calcualtes a new date based on the period ( number of two hour blocks ) and segements ( number of 15 minute intervals )
    calculateTime(period: number, segment: number): Date {
        const hoursToAdd = period * 2;
        const minutesToAdd = segment * 15;
        const startTime = this.userAvailability.WorkingHours.StartTime;
        const startdate = new Date(this.userAvailability.WorkingHours.StartTime);
        const hours = startdate.getHours();
        const minutes = startdate.getMinutes();
        const date = new Date();
        date.setHours(hours + hoursToAdd, minutes + minutesToAdd);
        return date;
    }

    getTimeLabel(period: number): string {
        const date = this.calculateTime(period, 0);
        const newTime = date.toTimeString().split(' ')[0];
        return newTime.substring(0, 5);
    }

    getPeriodText(t: string): string {
        let txt = 'Free';
        switch (t) {
            case '0':
                txt = 'Free';
                break;
            case '1':
                txt = 'Tentative';
                break;
            case '2':
                txt = 'Busy';
                break;
            case '3':
                txt = 'Out of office';
                break;
            case '4':
                txt = 'Working elsewhere';
                break;
            case '8':
                txt = 'Unavailable';
                break;
            default:
                txt = 'Free';
                break;
        }
        return txt;
    }

    getTooltip(t: string, p, i): string {
        const date = this.calculateTime(p, i);
        const txt = this.getPeriodText(t);
        return `${txt} - ${date.toTimeString().split(' ')[0].substring(0, 5)}`;
    }

    getSpanTime(p: number, i: number): string {
        if (!this.showTimeLabels) return '';

        const date = this.calculateTime(p, i);
        let hours = date.getHours();
        const minutes = date.getMinutes();

        if (this.show12HourTime && (i === 0 || i * 15 === 60)) {
            const ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            return `${hours}${ampm}`;
        } else {
            const timePortion = i * 15 === 60 || i === 0 ? 0 : 3;
            return date.toTimeString().split(' ')[0].substring(timePortion, 5);
        }
    }

    getAvailabilityPeriodDescription(): string {
        if (this.userAvailability) {
            return `${this.formatDate(this.userAvailability.WorkingHours.StartTime)} - ${this.formatDate(
                this.userAvailability.WorkingHours.EndTime
            )} ${this.userAvailability.WorkingHours.TimeZone}`;
        } else {
            return '';
        }
    }

    private formatDate(dateTimeString: string): string {
        const date = new Date(dateTimeString);
        const hh = String(date.getHours()).padStart(2, '0');
        const mm = String(date.getMinutes()).padStart(2, '0');
        return `${hh}:${mm}`;
    }

    splitIntoPeriods = (availabilityString: string): string[][] => {
        if (!availabilityString) availabilityString = '';

        const periods: string[][] = [];
        let p = 0;

        for (const status of availabilityString) {
            if (periods[p] && periods[p].length == 8) p++;
            if (!periods[p]) periods[p] = [];
            periods[p].push(status);
        }
        return periods;
    };

    getComparision(userAvailability: string[]): string {
        const maxLength = userAvailability.reduce((max, current) => {
            const currentLength = current ? current.length : 0;
            return Math.max(max, currentLength);
        }, 0);

        const sumArr: number[] = Array(maxLength).fill(0);

        for (const ua of userAvailability) {
            if (ua && ua) {
                const availabilityArr = ua.split('').map(Number);
                for (let i = 0; i < maxLength; i++) {
                    sumArr[i] += availabilityArr[i] || 0;
                    if (sumArr[i] > 0) sumArr[i] = 8;
                }
            }
        }
        return sumArr.join('');
    }
}
