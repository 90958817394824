import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';
import { Category } from '../../data/categories';
import * as uiActions from '../../ui/ui.actions';
import * as dataActions from '../../data/data.actions';
import { DirectoryUser } from '../../data/data.models';
import * as fromRoot from '../../reducers';
import { ChatHelper } from '../../users/components/chat-helper';
import { settingsFeature } from 'src/app/settings/settings.reducer';

@Component({
    selector: 'dir-person-details-modal',
    templateUrl: './person-details-modal.component.html',
    styleUrls: ['./person-details-modal.component.scss'],
})
export class PersonDetailsModalComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'modal-content';
    private unsubscribe$ = new Subject<void>();
    user: DirectoryUser | null = null;
    manager: DirectoryUser | null = null;
    prevId: string | null = null;
    nextId: string | null = null;
    managerId: string | null = null;
    chatHelper: ChatHelper;
    categories: Category[] = [];
    isTeamsChannel = false;
    hasNotBeenFound = false;
    userColumnSettings$ = this.store.pipe(select(fromRoot.selectUserColumnSettings));
    isScheduleHidden$ = this.store.pipe(select(settingsFeature.selectIsScheduleHidden));
    constructor(private store: Store, private router: Router) {}

    ngOnInit(): void {
        combineLatest([
            this.store.pipe(select(fromRoot.selectSelectedPersonId)),
            this.store.pipe(select(fromRoot.selectUsersSorted)),
            this.store.pipe(select(fromRoot.selectDisplayedUsers)),
            this.store.pipe(select(fromRoot.selectCategories)),
            this.store.pipe(select(fromRoot.selectTeamsEnvironment))
        ])
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(([_, allUsers, users, categories]) => allUsers != null && users != null)
            )
            .subscribe(([userId, allUsers, users, categories, teamsEnv]) => {
                this.categories = categories;
                if (userId != null) {
                    let idx = allUsers.findIndex((x) => x.id === userId);
                    if (idx === -1) {
                        this.user = null;
                        this.hasNotBeenFound = true;
                        this.prevId = null;
                        this.nextId = null;
                        return;
                    }

                    this.user = allUsers[idx];
                    this.managerId = this.user.managerId;
                    if (this.managerId) {
                        this.manager = allUsers.find((x) => x.id === this.managerId);
                    } else {
                        this.manager = null;
                    }
                    //now try to find the users in the currently displayed page
                    //if it's there, fill in the prev/next ids
                    //debugger
                    idx = users.findIndex((x) => x.id === userId);
                    if (idx > 0) {
                        this.prevId = users[idx - 1].id;
                    } else {
                        this.prevId = null;
                    }
                    if (idx > -1 && idx < users.length - 1) {
                        this.nextId = users[idx + 1].id;
                    } else {
                        this.nextId = null;
                    }

                    this.hasNotBeenFound = this.user == null;

                    if (teamsEnv != null) {
                        this.isTeamsChannel = teamsEnv.isTeamsChannel;
                    } else {
                        this.isTeamsChannel = false;
                    }

                    this.chatHelper = new ChatHelper(this.user.mail, this.isTeamsChannel);
                }
            });
    }


    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.prevId && event.key === 'ArrowLeft') {
            this.onChangePerson(this.prevId);
        } else if (this.nextId && event.key === 'ArrowRight') {
            this.onChangePerson(this.nextId);
        }
    }

    onChangePerson(id: string) {
        this.router.navigate([], {
            queryParams: {
                userId: id
            },
            queryParamsHandling: 'merge'
        });
    }

    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }

    isValidEmail(): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return this.user?.mail && emailRegex.test(this.user.mail);
    }

    onCall($event: MouseEvent): boolean {
        $event.preventDefault();
        $event.stopPropagation();
        const url = this.chatHelper.onCall([this.user.mail]);
        if (url && url.length > 0) {
            this.store.dispatch(uiActions.openDeepLink({ url }));
        }
        return false;
    }

    ngOnDestroy(): void {
        this.router.navigate([], {
            queryParams: {
                userId: null
            },
            queryParamsHandling: 'merge'
        });
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
