import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoggerModule, NGXLogger, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';

import {
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication
} from '@azure/msal-browser';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ROOT_REDUCERS, metaReducers } from './reducers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppEffects } from './app.effects';
import { TestComponent } from './test/test.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SignupComponent } from './auth/signup.component';
import { AuthEffects } from './auth/auth.effects';
import { DirectoryComponent } from './directory/directory.component';
import { DataEffects } from './data/data.effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalRootComponent } from './components/modals/modal-root.component';
import { PersonDetailsModalComponent } from './components/modals/person-details-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DepartmentEditorModalComponent } from './components/department-editor-modal.component';
import { DevPlanComponent } from './components/dev-plan.component';
import { CategoryItemsComponent } from './components/categories/category-items.component';
import { RedirectComponent } from './auth/redirect.component';
import { CategoryItemEditorModalComponent } from './components/categories/category-item-editor-modal.component';
import { CategoryToolbarComponent } from './components/categories/category-toolbar.component';
import { TeamsAuthComponent } from './auth/teams-auth.component';
import { SettingsEffects } from './settings/settings.effects';
import { ConfirmationDialogComponent } from './components/modals/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EditorFieldComponent } from './components/categories/editor-field.component';
import { DetailsFieldComponent } from './components/modals/details-field.component';
import { CategoryImageComponent } from './components/categories/category-image.component';
import { ImageEffects } from './images/image.effects';
import { ConsentComponent } from './components/consent.component';
import { ConsentResultComponent } from './components/consent-result.component';
import { InformationBannerComponent } from './directory/information-banner.component';
import { SubscriptionInformationModalComponent } from './components/modals/subscription-information-modal.component';
import { CategoryTagComponent } from './components/categories/category-tag.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MarkdownModule } from 'ngx-markdown';
import { TeamsTabComponent } from './auth/teams-tab.component';
import { TeamsScopesComponent } from './auth/teams-scopes.component';
import { TeamsEmptyComponent } from './auth/teams-empty.component';
import { PricingPageComponent } from './components/pricing-page.component';
import { LoginFailedComponent } from './auth/login-failed.component';
import { CityBrowserComponent } from './components/city-browser.component';
import { AvailabilityEffects } from './userAvailability/availability.effects';
import {
    AVAILABILITY_MAILBOXSETTINGS_ENDPOINT,
    AVAILABILITY_PRESENCE_ENDPOINT,
    AVAILABILITY_SCHEDULE_ENDPOINT
} from './userAvailability/services/availability.service';
import { UserCalendarComponent } from './userAvailability/components/user-calendar.component';
import { DeepLinkExecutorComponent } from './components/deep-link-executor.component';
import { SignalRService } from './services/signalr.service';
import { TocEffects } from './settings/toc.effects';
import { TocService } from './services/toc.service';
import { HelpModalComponent } from './components/modals/help-modal.component';
import { LoggerWriterService } from './utils/logger-mapper.provider';
import { TocWelcomeComponent } from './auth/toc-welcome.component';
import { InsightsService } from './services/insights.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { IntegrityEffects } from './integrity/integrity.effects';
import { IntegrityModule } from './integrity/integrity.module';
import { SharedModule } from './shared/shared.module';
import { IntegrityApplyModalComponent } from './components/modals/integrity-apply-modal.component';
import { SettingsModule } from './settings/settings.module';
import { AdminGuard } from './guards/admin.guard';
import { UsersModule } from './users/users.module';
import { LoggingInterceptor } from './utils/logging-interceptor';

export function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
    console.log(message);
}

function loggerCallbackFactory(logger: NGXLogger) {
    return (level: LogLevel, message: string, containsPii: boolean) => {
        switch (level) {
            case LogLevel.Error:
                logger.error(message);
                break;
            case LogLevel.Info:
                logger.info(message);
                break;
            case LogLevel.Verbose:
                logger.debug(message);
                break;
            case LogLevel.Warning:
                logger.warn(message);
                break;
            default:
                logger.trace(message);
        }
    };
}

export function MSALInstanceFactory(logger: NGXLogger): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.clientId,
            authority: 'https://login.microsoftonline.com/common',
            redirectUri: environment.redirectUri,
            clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
        },
        system: {
            allowNativeBroker: false,
            loggerOptions: {
                loggerCallback: loggerCallbackFactory(logger),
                piiLoggingEnabled: true,
                logLevel: LogLevel.Warning
            }
        }
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: window.isTeams ? InteractionType.Redirect : InteractionType.Popup,
        authRequest: {
            scopes: [environment.apiScope]
        },
        loginFailedRoute: window.isTeams ? '/teams-login-failed' : '/login-failed'
    };
}

export function MSALInterceptorConfigFactory(store: Store): MsalInterceptorConfiguration {
    return {
        interactionType:
            window.isTeams || !(window !== window.parent && !window.opener)
                ? InteractionType.Redirect
                : InteractionType.Popup,
        protectedResourceMap: new Map([
            ['https://graph.microsoft.com/v1.0/me', ['user.read', 'profile', { httpMethod: 'PATCH', scopes: ['user.readwrite'] }]],
            ['https://graph.microsoft.com/v1.0/users*', ['user.readbasic.all', 'user.read', { httpMethod: 'PATCH', scopes: ['user.readwrite.all'] }]],
            ['https://graph.microsoft.com/v1.0/*batch', ['user.readbasic.all', 'user.read']],
            [`${environment.serverUrl}/api*`, [environment.apiScope]],
            [`https://localhost:4200/settings/consent`, null], //TODO - why do we need this?  What about PROD!
            [AVAILABILITY_PRESENCE_ENDPOINT, ['presence.read.all']],
            [AVAILABILITY_SCHEDULE_ENDPOINT, ['Calendars.Read.Shared']],
            [AVAILABILITY_MAILBOXSETTINGS_ENDPOINT, ['MailboxSettings.Read']],
            [`${environment.tocUrl}/api/td/exists/*`, null],
            [`${environment.tocUrl}/api*`, [environment.apiScopeToc]]
        ])
    };
}

@NgModule({
    declarations: [
        AppComponent,
        TestComponent,
        SignupComponent,
        DirectoryComponent,
        ConsentComponent,
        ModalRootComponent,
        PersonDetailsModalComponent,
        SubscriptionInformationModalComponent,
        CategoryItemEditorModalComponent,
        DepartmentEditorModalComponent,
        HelpModalComponent,
        DevPlanComponent,
        InformationBannerComponent,
        CategoryItemsComponent,
        CategoryImageComponent,
        RedirectComponent,
        CategoryToolbarComponent,
        TeamsTabComponent,
        TeamsAuthComponent,
        ConfirmationDialogComponent,
        EditorFieldComponent,
        CategoryTagComponent,
        DetailsFieldComponent,
        ConsentResultComponent,
        UserCalendarComponent,
        TeamsScopesComponent,
        TeamsEmptyComponent,
        PricingPageComponent,
        LoginFailedComponent,
        DeepLinkExecutorComponent,
        CityBrowserComponent,
        TocWelcomeComponent,
        IntegrityApplyModalComponent
    ],
    bootstrap: [AppComponent, RedirectComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        LoggerModule.forRoot(
            {
                level: NgxLoggerLevel.INFO,
                enableSourceMaps: !environment.production
            },
            { writerProvider: { provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: LoggerWriterService } }
        ),
        MarkdownModule.forRoot(),
        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all providers for an @ngrx/store
         * based application.
         */
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers,
            runtimeChecks: {
                // strictStateImmutability and strictActionImmutability are enabled by default
                strictStateSerializability: true,
                strictActionSerializability: true,
                strictActionWithinNgZone: true,
                strictActionTypeUniqueness: true
            }
        }),
        IntegrityModule,
        SettingsModule,
        //StoreModule.forFeature(uiFeature),
        /**
         * EffectsModule.forRoot() is imported once in the root module and
         * sets up the effects class to be initialized immediately when the
         * application starts.
         *
         * See: https://ngrx.io/guide/effects#registering-root-effects
         */
        EffectsModule.forRoot([
            AppEffects,
            AuthEffects,
            DataEffects,
            SettingsEffects,
            ImageEffects,
            AvailabilityEffects,
            TocEffects,
            IntegrityEffects
        ]),
        /**
         * @ngrx/router-store keeps router state up-to-date in the store.
         */
        StoreRouterConnectingModule.forRoot({
            navigationActionTiming: NavigationActionTiming.PostActivation
        }),
        StoreDevtoolsModule.instrument({
            name: 'TeamDirectory',
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        }),
        ToastrModule.forRoot(),
        TypeaheadModule.forRoot(),
        CollapseModule.forRoot(),
        SharedModule,
        UsersModule,
        MsalModule,
        FontAwesomeModule,
        ProgressbarModule,
        PaginationModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        MatMenuModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatDialogModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: false && !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        //provideExperimentalCheckNoChangesForDebug({interval: 1000}),
        {
            provide: ErrorHandler,
            useClass: environment.appInsightsConnectionString !== '' ? ApplicationinsightsAngularpluginErrorService : ErrorHandler
        },
        InsightsService,
        SignalRService,
        TocService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggingInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [NGXLogger]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        AdminGuard,
        MsalService,
        MsalGuard,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}
