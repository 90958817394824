<!--begin::Image input-->
<div *ngIf="editMode && !isLibraryMode" dirDropZone (fileDropped)="onImageDrop($event)"
    class="image-input image-input-outline drop-zone" data-kt-image-input="true"
    style="background-image: url('assets/media/svg/avatars/blank.svg')">
    <!--begin::Preview existing avatar-->
    <div *ngIf="imageSrc" [style.background-size]="backgroundSize" [style.background-image]="'url(\''+imageSrc+'\')'"
        class="image-input-wrapper w-300px h-200px"></div>
    <div *ngIf="patternUrl && !imageSrc" [style.background-image]="'url(\''+patternUrl+'\')'"
        class="image-input-wrapper-pattern w-300px h-200px">
        <div class="background-overlay"></div>
    </div>
    <!--end::Preview existing avatar-->
    <!--begin::Edit-->
    <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
        data-kt-image-input-action="change" data-bs-toggle="tooltip" tooltip="Change picture" title="" data-bs-original-title="Change picture">
        <i class="bi bi-pencil-fill fs-7"></i>
        <!--begin::Inputs-->
        <input type="file" name="picture" accept=".png, .jpg, .jpeg" (change)="onFileChange($event)">
        <input type="hidden" name="picture_remove">
        <!--end::Inputs-->
    </label>
    <!--end::Edit-->
    <!--begin::Cancel-->
    <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
        data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="" data-bs-original-title="Cancel picture">
        <i class="bi bi-x fs-2"></i>
    </span>
    <!--end::Cancel-->
    <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
        data-kt-image-input-action="browse" tooltip="Browse picture library" (click)="isLibraryMode = true">
        <i class="bi bi-folder fs-2"></i>
    </span>
    <!--begin::Generate-->
    <span *ngIf="patternUrl && !imageSrc"
        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
        data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
        data-bs-original-title="Generate new picture" tooltip="Generate new picture" (click)="onGenerateNewPattern()">
        <i class="bi bi-arrow-clockwise fs-2"></i>
    </span>
    <!--end::Generate-->
    <!--begin::Remove-->
    <span *ngIf="imageSrc" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
        data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="" data-bs-original-title="Remove picture"
        tooltip="Remove picture" (click)="onClearFile()">
        <i class="bi bi-x fs-2"></i>
    </span>
    <!--end::Remove-->
</div>
<!--end::Image input-->
<dir-city-browser *ngIf="isLibraryMode" (selectImage)="onSelectCityImage($event)"
    [selectedImage]="imageId"></dir-city-browser>

<div *ngIf="!editMode">
    <div *ngIf="size === 'M'">
        <!--<div *ngIf="imageSrc" class="symbol symbol-200px symbol-2by3 mb-5">
            <img [src]="imageSrc" alt="image" />
        </div>-->
        <div *ngIf="imageSrc" class="img-container symbol symbol-200px symbol-2by3 mb-5">
            <div class="image-wrapper">
                <img [src]="imageSrc" alt="Your Image">
            </div>
        </div>
        <div *ngIf="pattern && ( !imageSrc || imageSrc.length === 0)" class="symbol symbol-200px symbol-2by3 mb-5">
            <div class="svg-background background-muted" [style.background-image]="'url(' + patternUrl + ')'"></div>
        </div>
    </div>
    <div *ngIf="size === 'S'">
        <div *ngIf="imageSrc" class="symbol symbol-35px">
            <img [src]="imageSrc" alt="user picture">
        </div>
        <div *ngIf="pattern && ( !imageSrc || imageSrc.length === 0)" class="symbol symbol-35px">
            <div class="svg-background background-muted" [style.background-image]="'url(' + patternUrl + ')'"></div>
        </div>
    </div>
    <div *ngIf="size ==='B'" class="background background-cover" [style.background-image]="'url(\'' + imageSrc + '\')'">
    </div>
</div>
