import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../reducers';
import * as AuthActions from '../auth/auth.actions';
import { DemoHelper } from '../utils/demo-helper';
import { Feature } from '../shared/components/features/features.models';

@Component({
    selector: 'dir-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
    @Input() isInline = false;

    isTeams$: Observable<boolean>;
    demoHelper = DemoHelper;
    features = Feature;
    constructor(
        private msalService: MsalService,
        private store: Store,
        private msalBroadcastService: MsalBroadcastService,
        private router: Router
    ) {}

    ngOnInit() {
        this.isTeams$ = this.store.pipe(select(fromRoot.selectIsTeams));
    }

    onConsentSuccess(feature: Feature) {
        if (feature === Feature.Directory) {
            this.router.navigate(['/directory']);
        }
    }

    onGoDemo() {
        this.demoHelper.ToggleDemoMode();
    }

    onLogout() {
        this.store.dispatch(AuthActions.msalLogout());
    }
}
