import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as dataActions from '../../data/data.actions';

@Component({
    selector: 'dir-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrl: './help-modal.component.scss'
})
export class HelpModalComponent {
    topic = '';
    constructor(private store: Store) {}
    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }
}
