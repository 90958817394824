import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { uiFeature } from '../ui/ui.reducer';
import { FrameCommunicationService } from '../services/frame-communication.service';
import { SubscriptionStatus } from '../auth/auth.models';

@Component({
    selector: 'dir-deep-link-executor',
    templateUrl: './deep-link-executor.component.html',
    styleUrls: ['./deep-link-executor.component.scss']
})
/*
* This component is used to handle deep links from M365 Hosts.  It opens the link in an iframe to launch the integration.
*/
export class DeepLinkExecutorComponent implements OnInit,OnDestroy {
    private unsubscribe$ = new Subject<void>();
    linkUrl: SafeUrl = '';
    triggerLink = false;
    subscriptionStatus: SubscriptionStatus = SubscriptionStatus.Subscribed;
    subscriptionStatus$: Observable<SubscriptionStatus>;
    constructor(private store: Store, private sanitizer: DomSanitizer, private tocCommunication : FrameCommunicationService) {
        // Listen for messages from TOC
        tocCommunication.Register();
    }

    ngOnInit(): void {

        this.store.pipe(select(uiFeature.selectDeepLink))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((url) => {
                if (url && url.length > 0) {
                    this.linkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    this.triggerLink = true;
                    setTimeout(() => {
                        this.triggerLink = false;
                    }, 5000);
                }
            });

        this.subscriptionStatus$ = this.store.pipe(select(fromRoot.selectSubscriptionStatus));
        this.subscriptionStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
            this.tocCommunication.readyToReceive(status);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.tocCommunication.Deregister();
    }


}

