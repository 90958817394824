import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    UserSchedulesRequest,
    UserPresence,
    UserAvailability,
    GraphUserPresence,
    GraphMailboxSettings,
    GraphScheduleInformation
} from '../availability.models';

export const AVAILABILITY_PRESENCE_ENDPOINT = 'https://graph.microsoft.com/v1.0/communications/getPresencesByUserId';
export const AVAILABILITY_SCHEDULE_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/calendar/getSchedule';
export const AVAILABILITY_MAILBOXSETTINGS_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/mailboxSettings';

@Injectable({ providedIn: 'root' })
export class AvailabilityService {
    constructor(private http: HttpClient) {}

    getUserPresences(ids: string[]): Observable<UserPresence[]> {
        return this.http
            .post<{ value: GraphUserPresence[] }>(AVAILABILITY_PRESENCE_ENDPOINT, { ids: ids })
            .pipe(
                map((response) =>
                    response.value.map(
                        (presenceInfo) =>
                            new UserPresence(
                                presenceInfo.id,
                                presenceInfo.availability,
                                presenceInfo.activity,
                                presenceInfo.statusMessage,
                                new Date()
                            )
                    )
                )
            );
    }

    getUserSchedule(mail: string[], dayModifier: number): Observable<UserAvailability[]> {
        return this.http.get<GraphMailboxSettings>(AVAILABILITY_MAILBOXSETTINGS_ENDPOINT).pipe(
            switchMap((mailboxSettings) => {
                const startTime = this.getTime(mailboxSettings.workingHours.startTime, dayModifier);
                const endTime = this.getTime(mailboxSettings.workingHours.endTime, dayModifier);

                const timeZone = mailboxSettings.workingHours.timeZone.name;

                const schedule: UserSchedulesRequest = {
                    schedules: mail,
                    startTime: {
                        dateTime: startTime,
                        timeZone: timeZone
                    },
                    endTime: {
                        dateTime: endTime,
                        timeZone: timeZone
                    },
                    availabilityViewInterval: 15
                };

                return this.http
                    .post<{ value: GraphScheduleInformation[] }>(AVAILABILITY_SCHEDULE_ENDPOINT, schedule)
                    .pipe(
                        map((response) => {
                            return response.value.map((a) => ({
                                Availability: a.availabilityView,
                                Mail: a.scheduleId,
                                NoCalendar: !a.availabilityView,
                                WorkingHours: {
                                    StartTime: startTime,
                                    EndTime: endTime,
                                    TimeZone: timeZone
                                }
                            }));
                        })
                    );
            })
        );
    }

    getTime(time: string, dayModifier: number): string {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + dayModifier);
        const [startHours, startMinutes, startSeconds] = time.split(':').map(Number);
        currentDate.setHours(startHours, startMinutes, startSeconds, 0);
        return currentDate.toISOString();
    }

    formatTime(time: string): string {
        const parts = time.split(':');
        return `${parts[0]}:${parts[1]}`;
    }
}
