import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TenantAccount } from '../auth/auth.models';

@Injectable({ providedIn: 'root' })
export class TenantService {
    constructor(private http: HttpClient) {}

    loadTenant(isFromToc = false) {
        return this.http.get<TenantAccount>('/api/tenant' + (isFromToc ? '?fromToc=true' : ''));
    }
}
