import { Injectable } from '@angular/core';
import { AvailabilityService } from './services/availability.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userPresenceActions from './availability.actions';
import { EMPTY, map, switchMap, withLatestFrom } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { ChatHelper } from '../users/components/chat-helper';
import * as uiActions from '../ui/ui.actions';

@Injectable()
export class AvailabilityEffects {
    constructor(private actions$: Actions, private availabilityService: AvailabilityService, private store: Store) {}

    loadUserPresences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userPresenceActions.loadUserPresences),
            withLatestFrom(this.store.pipe(select(fromRoot.selectUserPresences))),
            switchMap(([action, currentPresences]) => {
                const now = new Date();
                const idsToLoad = action.ids.filter((id) => {
                    const presence = currentPresences.find((cp) => cp.id == id);

                    return !presence || now.getTime() - new Date(presence.time).getTime() > 2 * 60 * 1000;
                });

                if (idsToLoad.length > 0) {
                    return this.availabilityService.getUserPresences(idsToLoad).pipe(
                        map((up) => {
                            return userPresenceActions.loadUserPresencesSuccess({ userPresences: up });
                        })
                    );
                } else {
                    return EMPTY;
                }
            })
        )
    );

    loadUserSchedules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userPresenceActions.loadUserSchedule),
            switchMap((action) => {
                return this.availabilityService.getUserSchedule(action.mail, action.dayModifier).pipe(
                    map((schedule) => {
                        return userPresenceActions.loadUserScheduleSuccess({ schedule });
                    })
                );
            })
        )
    );

    bookMeeting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userPresenceActions.bookMeeting),
            withLatestFrom(this.store.pipe(select(fromRoot.selectIsTeams))),
            switchMap(([action, inTeams]) => {
                //endTime is 30 minutes after meetingTime
                const endDate = new Date(action.duration);
                endDate.setMinutes(endDate.getMinutes() + 30);
                const endTime = `${endDate.toISOString().slice(0, 10)}T${
                    endDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }) + ':00'
                }`;

                if (inTeams) {
                    const helper: ChatHelper = new ChatHelper('', true);
                    if (helper.composeMeeting(action.mails, action.startTime, endTime)) {
                        return EMPTY;
                    }
                } else {
                    return [
                        uiActions.openDeepLink({
                            url: ChatHelper.composeMeetingDeeplink(action.mails, action.startTime, endTime)
                        })
                    ];
                }
            })
        )
    );
}
