import { Component } from '@angular/core';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'dir-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss']
})
export class TestComponent {
    faGamepad = faGooglePlusG;
}
