import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CityItem, CityPicturesUrlPrefix, citiesData } from 'src/app/data/cities';

@Component({
    selector: 'dir-city-browser',
    templateUrl: './city-browser.component.html',
    styleUrls: ['./city-browser.component.scss']
})
export class CityBrowserComponent implements OnInit {
    @Output()
    selectImage = new EventEmitter<string>();
    @Input()
    selectedImage: string;
    cities: CityItem;
    currentNode: CityItem;
    parents: CityItem[] = [];
    selected: CityItem = null;
    preview: { variant: string; path: string; width: number } = null;

    ngOnInit() {
        // this.http.get<any[]>('/assets/data/cities.json').subscribe((data) => {
        //     this.cities = data;
        //     this.parents = [];
        //     this.currentNode = this.cities;
        // });
        this.cities = citiesData;
        this.parents = [];
        this.currentNode = this.cities;
        if (this.selectedImage.startsWith(CityPicturesUrlPrefix)) {
            //search this.cities recursively for this.selectedImage using this comparator (i) => `${CityPicturesUrlPrefix}${i.name}` === name
            //and set this.selected to the found item
            //construct the tree of parents while searching
            const result = this.findCityRecursive(this.selectedImage, [this.cities]);
            if (result) {
                this.currentNode = result.parents.pop();
                this.selected = result.city;
                this.parents = result.parents;
                this.preview = this.selected.variants.find((variant) => variant.width == 2400);
            }
        }
        console.log(this.currentNode);
    }

    private findCityRecursive(
        name: string,
        cities: CityItem[],
        parents: CityItem[] = []
    ): { city: CityItem; parents: CityItem[] } {
        for (const city of cities) {
            if (`${CityPicturesUrlPrefix}${city.name}` === name) {
                this.selected = city;
                //parents.pop();
                return { city, parents };
            } else if (city.contents) {
                const result = this.findCityRecursive(name, city.contents, [...parents, city]);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    navigate(node: CityItem, $event: MouseEvent) {
        this.parents.push(this.currentNode);
        this.currentNode = node;
        this.selected = null;
        $event.stopPropagation();
        $event.preventDefault();
        return false;
    }
    navigateUp($event: MouseEvent) {
        this.currentNode = this.parents.pop();
        this.selected = null;
        $event.stopPropagation();
        $event.preventDefault();
        return false;
    }

    select(node: CityItem, $event: MouseEvent) {
        this.selected = node;
        //find 600px variant and use that as preview
        this.preview = node.variants.find((variant) => variant.width == 2400);
        $event.stopPropagation();
        $event.preventDefault();
        return false;
    }
}
