import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dir-teams-empty',
    templateUrl: './teams-empty.component.html',
    styleUrls: ['./teams-empty.component.scss']
})
export class TeamsEmptyComponent implements OnInit {
    ngOnInit(): void {
        console.log('TeamsEmptyComponent ngOnInit');
    }
}
