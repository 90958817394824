@let isTrial = isTrial$|async;
<div
    *ngIf="!online"
    class="d-none d-sm-flex flex-row justify-content-center align-items-center offline-banner bg-warning">
    Warning - You are currently offline.
</div>

<div *ngIf="isDemo" class="d-none d-sm-flex flex-row demo-bar justify-content-center align-items-center">
    TeamDirectory is running in
    <span class="fw-bold">&nbsp;demo&nbsp;</span>
    mode.
    <a routerLink="/consent" class="btn btn-warning btn-narrow fw-bold mx-2 link-dark">Connect to your data.</a>
</div>

<div
    *ngIf="isTrial || showSubscriptionStatus()" @slideDown
    class="d-none d-sm-flex flex-row justify-content-center align-items-center info-banner"
    [class.info-banner-trial]="tenantAccount && tenantAccount.subscriptionStatus === 0"
    [class.info-banner-lapsed]="
        tenantAccount && (tenantAccount.subscriptionStatus === 2 || tenantAccount.subscriptionStatus === 3)
    "
    (click)="onInformation()">
    <span *ngIf="isTrial || (tenantAccount && tenantAccount.subscriptionStatus === 0)">
        Trial mode - See pricing details and subscribe
    </span>
    <span *ngIf="tenantAccount && tenantAccount.subscriptionStatus === 2">
        Your trial has finished - See pricing details and subscribe to continue to use TeamDirectory
    </span>
    <span *ngIf="tenantAccount && tenantAccount.subscriptionStatus === 3">
        Your subscription has lapsed - Click here to see pricing details and subscribe to continue to use TeamDirectory
    </span>
    <span *ngIf="!hostedInTOC && tenantAccount && tenantAccount.subscriptionStatus !== 0 && !isDemo">
        <button class="btn btn-warning btn-narrow btn-sm" (click)="onDemo()">or continue with Demo Data</button>
    </span>

    <span *ngIf="hostedInTOC && tenantAccount && tenantAccount.subscriptionStatus !== 0">
        <button class="btn btn-danger btn-narrow btn-sm p-3" (click)="onRevertTOCDirectory()">
            Or switch back to basic directory
        </button>
    </span>

    <button *ngIf="isDemo" (click)="offDemo()" class="btn btn-sm">X</button>
</div>
