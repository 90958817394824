/* eslint-disable */
import { XMLNode } from './xml';

export class SVG {
    width: number;
    height: number;
    svg: XMLNode;
    context: XMLNode[];

    constructor() {
        this.width = 100;
        this.height = 100;
        this.svg = new XMLNode('svg');
        this.context = []; // Track nested nodes
        this.setAttributes(this.svg, {
            xmlns: 'http://www.w3.org/2000/svg',
            width: String(this.width),
            height: String(this.height)
        });
    }

    // This is a hack so groups work.
    currentContext() {
        return this.context[this.context.length - 1] || this.svg;
    }

    // This is a hack so groups work.
    end() {
        this.context.pop();
        return this;
    }

    // This is a hack so groups work.
    currentNode() {
        const context = this.currentContext();
        return context.lastChild || context;
    }

    transform(transformations: any) {
        this.currentNode().setAttribute(
            'transform',
            Object.keys(transformations)
                .map(function (transformation) {
                    return transformation + '(' + transformations[transformation].join(',') + ')';
                })
                .join(' ')
        );
        return this;
    }

    setAttributes(el: SVGElement | XMLNode, attrs: { [key: string]: string }) {
        Object.keys(attrs).forEach(function (attr) {
            el.setAttribute(attr, attrs[attr]);
        });
    }

    setWidth(width: number) {
        this.svg.setAttribute('width', String(Math.floor(width)));
    }

    setHeight(height: number) {
        this.svg.setAttribute('height', String(Math.floor(height)));
    }

    toString() {
        return this.svg.toString();
    }

    rect(x, y?, width?, height?, args?) {
        // Accept array first argument
        const self: SVG = this;
        if (Array.isArray(x)) {
            x.forEach(function (a) {
                self.rect.apply(self, a.concat(args));
            });
            return this;
        }

        const rect: XMLNode = new XMLNode('rect');
        self.currentContext().appendChild(rect);
        this.setAttributes(rect, {
            ...{
                x: x,
                y: y,
                width: width,
                height: height
            },
            ...args
        });

        return this;
    }

    circle(cx, cy, r, args) {
        const circle = new XMLNode('circle');
        this.currentContext().appendChild(circle);
        this.setAttributes(circle, {
            ...{
                cx: cx,
                cy: cy,
                r: r
            },
            ...args
        });

        return this;
    }

    path(str, args) {
        var path = new XMLNode('path');
        this.currentContext().appendChild(path);
        this.setAttributes(path, {
            ...{
                d: str
            },
            ...args
        });

        return this;
    }

    polyline(str, args = {}) {
        // Accept array first argument
        var self = this;
        if (Array.isArray(str)) {
            str.forEach(function (s) {
                self.polyline(s, args);
            });
            return this;
        }

        var polyline = new XMLNode('polyline');
        this.currentContext().appendChild(polyline);
        this.setAttributes(polyline, {
            ...{
                points: str
            },
            ...args
        });

        return this;
    }

    // group and context are hacks
    group(args: any) {
        var group = new XMLNode('g');
        this.currentContext().appendChild(group);
        this.context.push(group);
        this.setAttributes(group, { ...args });
        return this;
    }
}
