import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TenantAccount } from '../../auth/auth.models';
import * as dataActions from '../../data/data.actions';
import * as fromRoot from '../../reducers';
import { uiFeature } from 'src/app/ui/ui.reducer';

export enum SubscriptionTab {
    Enterprise = 'enterprise',
    User = 'user'
}

@Component({
    selector: 'dir-subscription-information',
    templateUrl: './subscription-information-modal.component.html',
    styleUrls: ['./subscription-information-modal.component.scss'],
    animations: [
        trigger('fadeIn', [
            state('in', style({ opacity: 1, display: 'block' })),
            state('out', style({ opacity: 0, display: 'none' })),
            transition('in => out', [
                sequence([
                    style({ opacity: 1 }),
                    animate('0.15s ease-in-out', style({ opacity: 0 })),
                    style({ opacity: 0, display: 'none' })
                ])
            ]),
            transition('out => in', [
                sequence([
                    style({ display: 'block', position: 'absolute' }),
                    animate('0.15s ease-in-out', style({ opacity: 0 })),
                    style({ position: 'relative' }),
                    animate('0.15s ease-in-out', style({ opacity: 1, display: 'block' })),
                    style({ opacity: 1 })
                ])
            ])
        ])
    ]
})
export class SubscriptionInformationModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() isInline = false;

    private unsubscribe$ = new Subject<void>();
    activeTab: SubscriptionTab = SubscriptionTab.Enterprise;
    subscriptionsTab = SubscriptionTab;
    tenantAccount$: Observable<TenantAccount>;
    tenantAccount: TenantAccount;
    hostedInTOC = false;
    userObjectId$: Observable<string>;
    userObjectId = '';
    billingPeriod: 'monthly' | 'yearly' = 'yearly';
    plans: {
        size: string;
        description: string;
        monthly: string;
        yearly: string;
        yearlyMonthDollars: string;
        yearlyMonthCents: string;
        codeMonthly: string;
        codeYearly: string;
    }[] = [
        {
            size: 'Small organization',
            description: 'Between 1 and 199 people',
            monthly: '25',
            yearly: '200',
            yearlyMonthDollars: '16',
            yearlyMonthCents: '67',
            codeMonthly: 'TD_SMALL_MONTH',
            codeYearly: 'TD_SMALL_YEAR'
        },
        {
            size: 'Medium organization',
            description: 'Between 200 and 749 people',
            monthly: '60',
            yearly: '500',
            yearlyMonthDollars: '41',
            yearlyMonthCents: '67',
            codeMonthly: 'TD_MED_MONTH',
            codeYearly: 'TD_MED_YEAR'
        },
        {
            size: 'Large organization',
            description: '750 or more people',
            monthly: '140',
            yearly: '1200',
            yearlyMonthDollars: '100',
            yearlyMonthCents: '',
            codeMonthly: 'TD_LARGE_MONTH',
            codeYearly: 'TD_LARGE_YEAR'
        }
    ];

    constructor(private store: Store) {
        this.store
            .select(uiFeature.selectHostedInTOC)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hostedInTOC) => {
                this.hostedInTOC = hostedInTOC;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.tenantAccount$ = this.store.pipe(select(fromRoot.selectTenantAccount));

        this.tenantAccount$.pipe(takeUntil(this.unsubscribe$)).subscribe((account) => {
            this.tenantAccount = account;
        });

        this.userObjectId$ = this.store.pipe(select(fromRoot.selectUserObjectId));
        this.userObjectId$.pipe(takeUntil(this.unsubscribe$)).subscribe((userObjectId) => {
            this.userObjectId = userObjectId;
        });
    }

    ngAfterViewInit() {
        this.loadScript();
    }

    loadScript() {
        const script = document.createElement('script');
        script.text = `
      (function (document, src, libName, config) {
        var script = document.createElement('script');
        script.src = src;
        script.async = true;
        var firstScriptElement = document.getElementsByTagName('script')[0];
        script.onload = function () {
            for (var namespace in config) {
                if (config.hasOwnProperty(namespace)) {
                    window[libName].setup.setConfig(namespace, config[namespace]);
                }
            }
            window[libName].register();
        };

        firstScriptElement.parentNode.insertBefore(script, firstScriptElement);
    })(document, 'https://secure.2checkout.com/checkout/client/twoCoInlineCart.js', 'TwoCoInlineCart', { "app": { "merchant": "TEAMIMPR", "iframeLoad": "checkout" }, "cart": { "host": "https://secure.2checkout.com", "customization": "inline-one-step" } });


    `;
        document.getElementsByTagName('body')[0].appendChild(script); // appending to the body
    }

    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }

    onBuy(product: string) {
        this.store.dispatch(dataActions.dialogHide());

        const TwoCoInlineCart = window['TwoCoInlineCart'];

        //TwoCoInlineCart.cart.setTest(true);
        TwoCoInlineCart.cart.setCurrency('USD');
        TwoCoInlineCart.products.add({
            code: product,
            quantity: 1,
            additionalFields: [
                {
                    code: 'TID',
                    value: this.tenantAccount.id
                },
                {
                    code: 'UID',
                    value: this.userObjectId
                }
            ]
        });

        TwoCoInlineCart.cart.checkout();
    }
}
