import { InjectionToken, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment, ViewTransitionsFeatureOptions } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { SignupComponent } from './auth/signup.component';
import { TeamsAuthComponent } from './auth/teams-auth.component';
import { DevPlanComponent } from './components/dev-plan.component';
import { ConsentComponent } from './components/consent.component';
import { ConsentResultComponent } from './components/consent-result.component';
import { TeamsTabComponent } from './auth/teams-tab.component';
import { TeamsScopesComponent } from './auth/teams-scopes.component';
import { TeamsEmptyComponent } from './auth/teams-empty.component';
import { PricingPageComponent } from './components/pricing-page.component';
import { LoginFailedComponent } from './auth/login-failed.component';
import { FrameCommunicationService } from './services/frame-communication.service';
import { TocWelcomeComponent } from './auth/toc-welcome.component';
import { DirectoryComponent } from './directory/directory.component';
import { environment } from 'src/environments/environment';
//import { VIEW_TRANSITION_OPTIONS } from '@angular/router/esm2022/src/utils/view_transition';

export const VIEW_TRANSITION_OPTIONS = new InjectionToken<
    ViewTransitionsFeatureOptions & { skipNextTransition: boolean }
>(!environment.production ? 'view transition options' : '');

export const routes: Routes = [
    {
        path: 'register',
        component: SignupComponent
    },
    {
        path: 'fromtoc',
        component: SignupComponent
    },
    {
        path: 'welcometoc',
        component: TocWelcomeComponent
    },
    {
        path: 'pricing',
        component: PricingPageComponent
    },
    {
        path: 'callback',
        component: TeamsEmptyComponent
    },
    {
        path: 'login-failed',
        component: LoginFailedComponent
    },
    {
        path: 'dev',
        component: DevPlanComponent
    },
    {
        path: 'tab-config',
        component: TeamsTabComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'consent',
        component: ConsentComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'consent-result',
        component: ConsentResultComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'auth-teams',
        component: TeamsAuthComponent
    },
    {
        path: 'auth-teams-scopes',
        component: TeamsScopesComponent
    },
    {
        path: '',
        component: SignupComponent
    },
    // {
    //     matcher: (segments, group, route) => {
    //         if (segments.length > 0 && segments[0].path === 'settings') {
    //             const result: UrlMatchResult = { consumed: segments.slice(0, 2), posParams: {} };
    //             if (segments.length > 1) {
    //                 result.posParams.tab = new UrlSegment(segments[1].path, {});
    //             }
    //             return result;
    //         }
    //         return null;
    //     },
    //     component: SettingsComponent,
    //     canActivate: [MsalGuard],
    //     canDeactivate: [SettingsGuard]
    // },
    {
        matcher: (segments, group, route) => {
            if (segments.length > 0 && segments[0].path === 'directory') {
                const result: UrlMatchResult = { consumed: segments.slice(0, 3), posParams: {} };

                let url = '';
                if (segments.length > 1) {
                    result.posParams.category = new UrlSegment(segments[1].path, {});
                    url = segments[1].path;
                }
                if (segments.length > 2) {
                    result.posParams.slug = new UrlSegment(segments[2].path, {});
                    url = url + '/' + segments[2].path;
                }

                FrameCommunicationService.sendUrl(url);

                return result;
            }
            return null;
        },
        component: DirectoryComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'integrity',
        loadChildren: () => import('./integrity-ui/integrity-ui.module').then((m) => m.IntegrityUiModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings-ui/settings-ui.module').then((m) => m.SettingsUiModule)
    },
    { path: 'profile', loadChildren: () => import('./profile-ui/profile-ui.module').then((m) => m.ProfileUiModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, enableViewTransitions: false })],
    providers: [
        {
            provide: VIEW_TRANSITION_OPTIONS,
            useValue: {
                skipInitialTransition: false,
                onViewTransitionCreated: (transitionInfo) => {
                    console.error('View transition created', transitionInfo);
                }
            } as ViewTransitionsFeatureOptions
        }
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
