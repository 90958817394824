/* eslint-disable */
class SHA1 {
    private h0 = 0x67452301;
    private h1 = 0xefcdab89;
    private h2 = 0x98badcfe;
    private h3 = 0x10325476;
    private h4 = 0xc3d2e1f0;
    // The first 64 bytes (16 words) is the data chunk
    private block = new Uint32Array(80);
    private offset = 0;
    private shift = 24;
    private totalLength = 0;

    // We have a full block to process.  Let's do it!
    private processBlock() {
        // Extend the sixteen 32-bit words into eighty 32-bit words:
        for (let i = 16; i < 80; i++) {
            let w = this.block[i - 3] ^ this.block[i - 8] ^ this.block[i - 14] ^ this.block[i - 16];
            this.block[i] = (w << 1) | (w >>> 31);
        }

        // log(block);

        // Initialize hash value for this chunk:
        let a = this.h0;
        let b = this.h1;
        let c = this.h2;
        let d = this.h3;
        let e = this.h4;
        let f: number;
        let k: number;

        // Main loop:
        for (let i = 0; i < 80; i++) {
            if (i < 20) {
                f = d ^ (b & (c ^ d));
                k = 0x5a827999;
            } else if (i < 40) {
                f = b ^ c ^ d;
                k = 0x6ed9eba1;
            } else if (i < 60) {
                f = (b & c) | (d & (b | c));
                k = 0x8f1bbcdc;
            } else {
                f = b ^ c ^ d;
                k = 0xca62c1d6;
            }
            const temp = ((a << 5) | (a >>> 27)) + f + e + k + (this.block[i] | 0);
            e = d;
            d = c;
            c = (b << 30) | (b >>> 2);
            b = a;
            a = temp;
        }

        // Add this chunk's hash to result so far:
        this.h0 = (this.h0 + a) | 0;
        this.h1 = (this.h1 + b) | 0;
        this.h2 = (this.h2 + c) | 0;
        this.h3 = (this.h3 + d) | 0;
        this.h4 = (this.h4 + e) | 0;

        // The block is now reusable.
        this.offset = 0;
        for (let i = 0; i < 16; i++) {
            this.block[i] = 0;
        }
    }

    private write(byte) {
        this.block[this.offset] |= (byte & 0xff) << this.shift;
        if (this.shift) {
            this.shift -= 8;
        } else {
            this.offset++;
            this.shift = 24;
        }

        if (this.offset === 16) {
            this.processBlock();
        }
    }

    private updateString(str: string) {
        const length = str.length;
        this.totalLength += length * 8;
        for (let i = 0; i < length; i++) {
            this.write(str.charCodeAt(i));
        }
    }

    // The user gave us more data.  Store it!
    update(chunk) {
        if (typeof chunk === 'string') {
            return this.updateString(chunk);
        }
        const length = chunk.length;
        this.totalLength += length * 8;
        for (let i = 0; i < length; i++) {
            this.write(chunk[i]);
        }
    }

    private toHex(word) {
        let hex = '';
        for (let i = 28; i >= 0; i -= 4) {
            hex += ((word >> i) & 0xf).toString(16);
        }
        return hex;
    }

    // No more data will come, pad the block, process and return the result.
    digest() {
        // Pad
        this.write(0x80);
        if (this.offset > 14 || (this.offset === 14 && this.shift < 24)) {
            this.processBlock();
        }
        this.offset = 14;
        this.shift = 24;

        // 64-bit length big-endian
        this.write(0x00); // numbers this big aren't accurate in javascript anyway
        this.write(0x00); // ..So just hard-code to zero.
        this.write(this.totalLength > 0xffffffffff ? this.totalLength / 0x10000000000 : 0x00);
        this.write(this.totalLength > 0xffffffff ? this.totalLength / 0x100000000 : 0x00);
        for (let s = 24; s >= 0; s -= 8) {
            this.write(this.totalLength >> s);
        }

        // At this point one last processBlock() should trigger and we can pull out the result.
        return (
            this.toHex(this.h0) + this.toHex(this.h1) + this.toHex(this.h2) + this.toHex(this.h3) + this.toHex(this.h4)
        );
    }
}

// Input chunks must be either arrays of bytes or "raw" encoded strings
export function sha1(buffer: any) {
    // if (buffer === undefined) {
    //     return new SHA1();
    // }
    const shasum = new SHA1();
    shasum.update(buffer);
    return shasum.digest();
}
