import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { catchError, concatMap, filter, map } from 'rxjs';
import * as microsoftTeams from '@microsoft/teams-js';
import { AuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'dir-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
    constructor(private authService: MsalService) {}

    ngOnInit(): void {
        this.authService
            .handleRedirectObservable()
            .pipe(filter((result) => result != null))
            .subscribe({
                next: (response) => {
                    console.log('handling redirect success', response);
                    if (response.accessToken || response.idToken)
                        if (environment.debugDelays) {
                            setTimeout(() => this.msTeamsNotifySuccess(), 5000);
                        } else {
                            this.msTeamsNotifySuccess();
                        }
                },
                error: (error: AuthError) => {
                    console.log('handling redirect error', error);
                    // if (error instanceof InteractionRequiredAuthError) {
                    //     const errorCode = (error as InteractionRequiredAuthError).errorCode;
                    //     console.log(errorCode);
                    //     if (errorCode === 'consent_required' || errorCode === 'interaction_required') {
                    //     }
                    // }
                    if (environment.debugDelays) {
                        setTimeout(() => this.msTeamsNotifyFailureFactory(error)(), 5000);
                    } else {
                        this.msTeamsNotifyFailureFactory(error)();
                    }
                }
            });
        console.log('initializing teams in redirect component');
    }

    msTeamsNotifySuccess() {
        microsoftTeams.app.initialize().then(() => {
            console.log('initialized teams in POPUP');
            microsoftTeams.authentication.notifySuccess();
        });
    }

    msTeamsNotifyFailureFactory(error: AuthError) {
        return () => {
            microsoftTeams.app.initialize().then(() => {
                microsoftTeams.authentication.notifyFailure(error.errorCode);
            });
        };
    }
}
